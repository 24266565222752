<app-bread-crumb></app-bread-crumb>
<div class="qa">
  <!-- 手機版 -->
  <div class="mb">
      <div class="wrapper">
    <main *ngIf="qalist?.length" >
      <p-dropdown [options]="qalist" optionLabel="CCategoryName" (onChange)="selectChange($event)"></p-dropdown>
    </main>
  </div>
    <ng-container *ngFor="let item of qalist">
      <app-qa-info [data]="item.FaqList"  *ngIf="item.CCategoryMainId === selectedCategoryId"></app-qa-info>
    </ng-container>
  </div>



  <!-- //電腦版 -->
  <p-tabView class="web">
    <p-tabPanel [header]="item.CCategoryName" *ngFor="let item of qalist">
      <app-qa-info [data]="item.FaqList"></app-qa-info>
      <!-- <ng-container *ngFor="let data of item.FaqList;let i = index">
        <app-qa-info [data]=" item.FaqList"></app-qa-info>
      <div class="wrapper">
        <main>
            <p-panel class="p-panel" [header]="data.CQuestion" [collapsed]="true" [toggleable]="true">
              <ng-template pTemplate="headericons">
                <img class="black-arrow" src="/assets/images/open.svg" />
              </ng-template>
              <div class="panel-item">
                <div class="desc" [innerHtml]="data.CAnswer|html"></div>
              </div>
            </p-panel>
          </main>
        </div>
      </ng-container> -->
    </p-tabPanel>
  </p-tabView>
</div>
