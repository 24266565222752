<app-bread-crumb></app-bread-crumb>
<div class="form">
  <!-- 手機版 -->
  <div class="mb">
    <div class="wrapper">
      <main *ngIf="doctype.length">
        <p-dropdown [options]="doctype" optionLabel="CName"  (onChange)="selectChange($event)"></p-dropdown>
      </main>
    </div>
      <app-form-download [data]="doclist"  (onChange)="getCategorySubID($event)" [isActiveSubId]="isActiveSubId" ></app-form-download>
  </div>
  <!-- //電腦版 -->
  <p-tabView class="web" [(activeIndex)]="activeIndex" (activeIndexChange)="activeIndexChange($event)">
    <p-tabPanel [header]="item.CName" *ngFor="let item of doctype">
        <app-form-download [data]="doclist" [subData]="item.ChildTypes" (onChange)="getCategorySubID($event)" [isActiveSubId]="isActiveSubId"></app-form-download>
    </p-tabPanel>
  </p-tabView>
</div>
