export const environment = {
  //   production: false,
  //  apiUrl: 'http://60.251.54.95:8021/webapi/api',
  //  domain: 'http://60.251.54.95:8021',
  //    apiUrl: 'http://web/webapi/api',
 //     domain: 'http://web',
  //   company:'街口',
  production: true,
  apiUrl: '/webapi/api',
  company:'街口',
  domain: 'https://www.jkoam.com',
};
